.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-appear 1 20s linear;
  }
}

.App-header {
  background-color: #326473;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  background-color: #477b8a;
  min-height: 50vh;
  height: calc(70vh - 150px);
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 50px;
  align-items: normal;
  justify-content: normal;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #8cbac6;
  height: 50px;
  display: flex;
  flex-direction: column;
  font-size: calc(6px + 1vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-appear {
  from {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}
